import styled from '@emotion/styled';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.content};
  box-shadow: rgb(41 35 92 / 3%) 0px -2px 8px 4px;
  border-radius: 3px;
  padding: 5px;
  margin-top: 15px;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.sidebar};
  color: ${({ theme }) => theme.buttons.primary.textColor};
  border-radius: 3px;

  &.big {
    height: 70px;
  }
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  border-radius: 3px;
  color: ${({ theme }) => theme.font.default};

  &.big {
    height: 80px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.mainBackground};
  }
`;

export const TableHeaderCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
  border-right: 1px solid ${({ theme }) => theme.colors.mainBackground};
  max-width: ${({ maxWidth }) =>
    typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth};
  min-width: ${({ minWidth }) =>
    typeof minWidth === 'number' ? `${minWidth}px` : minWidth};
`;

export const TableCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  width: 100%;
  padding: 0 10px;
  border-right: 1px solid ${({ theme }) => theme.colors.mainBackground};
  max-width: ${({ maxWidth }) =>
    typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth};
  min-width: ${({ minWidth }) =>
    typeof minWidth === 'number' ? `${minWidth}px` : minWidth};
  cursor: ${({ clickable }) => (clickable ? `pointer` : '')};
  text-decoration: ${({ clickable }) => (clickable ? `underline` : '')};
  align-items: ${({ align }) => align};
  word-break: break-word;

  &.actions {
    padding: 5px;
    flex-direction: row;
    justify-content: start;
    gap: 5px;
  }

  &.text-cut {
    white-space: nowrap;
    overflow: hidden;
    min-width: 0;
  }
`;

export const ActionButton = styled.button`
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.sidebar};
  color: ${({ theme }) => theme.buttons.tertiary.textColor};
  padding: 7px 10px;
  max-height: 44px;

  &:hover {
    color: ${({ theme }) => theme.buttons.tertiary.hover};
    //border-color: ${({ theme }) => theme.buttons.tertiary.hover};
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px;
  gap: 16px;
  height: 40px;
  color: ${({ theme }) => theme.font.default};
`;

export const PaginationLabel = styled.div`
  display: flex;
  line-height: 40px;

  .icon {
    margin: auto 0;
  }

  &.disabled {
    color: ${({ theme }) => theme.colors.disabled};
  }

  &.cursor {
    cursor: pointer;
  }
`;

export const SelectContainer = styled.div`
  width: 120px;

  &.left {
    width: 150px;
    margin-right: auto;
  }

  &.small {
    width: 80px;
  }
`;

export const ExcelButtonContainer = styled.div`
  width: 120px;
  padding-top: 2px;

  &.left {
    width: 150px;
    margin-right: auto;
  }
`;
