import { makeAutoObservable } from 'mobx';
import { toggle, hide } from 'slidetoggle';
import routes from '@routes';

const PARENT_PATHS = ['reports'];

export class SidebarStore {
  expandedItem = '';

  submenusItems = {
    reports: [
      {
        label: 'Content Publications',
        path: routes.contentPublication.index,
      },
      {
        label: 'Summaries',
        path: routes.summaryReports.index,
      },
      {
        label: 'Client Coverages',
        path: routes.clientCoverages.index,
      },
      {
        label: 'Contents',
        path: routes.contentReports.index,
      },
      {
        label: 'NLA Aggregates',
        path: routes.nlaAggregates.index,
      },
      {
        label: 'Notifications',
        path: routes.notificationReports,
      },
      {
        label: 'AAS',
        path: routes.aasReportRequest,
      },
    ],
  };

  menuCollapsed = false;

  mobileMenuOpened = false;

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  autoOpen = (pathname) => {
    PARENT_PATHS.forEach((key) => {
      if (pathname.includes(`${key}/`) && this.expandedItem !== key) {
        this.expandedItem = key;
      }
    });
  };

  toggleSubmenu = (key, { current: submenu }) => {
    if (this.menuCollapsed) {
      this.menuCollapsed = false;
    }
    this.hideAllSubmenus();

    if (this.expandedItem !== key) {
      toggle(submenu, {
        miliseconds: 300,
        transitionFunction: 'ease-in',
        elementDisplayStyle: 'block',
      });
      this.expandedItem = key;
    } else {
      this.expandedItem = '';
    }
  };

  setMenuCollapsed = (isCollapsed) => {
    this.menuCollapsed = isCollapsed;
    if (isCollapsed) {
      this.expandedItem = '';
      this.hideAllSubmenus();
    }
  };

  // eslint-disable-next-line class-methods-use-this
  hideAllSubmenus = () => {
    const submenus = document.querySelectorAll('[data-toggable]');
    Array.prototype.forEach.call(submenus, (submenu) => {
      hide(submenu, {
        miliseconds: 300,
        transitionFunction: 'ease-in',
        elementDisplayStyle: 'block',
      });
    });
  };

  setMobileMenuOpened = (isOpened) => {
    this.mobileMenuOpened = isOpened;
    if (isOpened) {
      document.querySelector('body').classList.add('scroll-lock');
    } else {
      document.querySelector('body').classList.remove('scroll-lock');
    }
  };

  logout = async () => {
    this.isAuthenticated = false;
  };
}

export default new SidebarStore();
