import React, { useMemo } from 'react';
import xlsx from 'json-as-xlsx';
import Button from '@components/Button';
import Preloader, { POSITIONS, SIZES } from '@components/Preloader';

const autoLabel = (label) => `${label.split('_').join(' ')}:`;

const ExcelDownload = ({
  data,
  name,
  keys,
  formatLabel,
  buttonDisabled,
  valueKey = 'key',
  forTable,
  onExcelDownload,
  isLoading,
}) => {
  const settings = useMemo(
    () => ({
      fileName: name,
      extraLength: 3,
      writeOptions: {},
    }),
    [name],
  );

  const columns = useMemo(() => {
    const excelColumns = [];
    if (keys) {
      keys.forEach((key) => {
        const label = key.label ? key.label : autoLabel(key[valueKey]);
        excelColumns.push({
          label: formatLabel ? formatLabel(label) : label,
          value: key[valueKey],
          format: key.excelFormat,
        });
      });
    }
    return excelColumns;
  }, [keys, formatLabel, data, valueKey]);

  const content = useMemo(() => {
    const dateKeys = keys.filter(
      (key) => key.excelFormat || key[valueKey].includes('date'),
    );
    return data?.map((originalObj) => {
      const obj = { ...originalObj };
      dateKeys.forEach((key) => {
        const value = obj[key[valueKey]];
        obj[key[valueKey]] =
          value !== '' && value !== null && value !== undefined
            ? new Date(obj[key[valueKey]])
            : '-';
      });
      return obj;
    });
  }, [data, keys]);

  const handleExcelDownload = () => {
    if (forTable && (!data || data.length === 0)) {
      onExcelDownload();
      return;
    }
    const excelData = [
      {
        sheet: name,
        columns,
        content,
      },
    ];
    xlsx(excelData, settings);
  };

  const label = 'Download';
  const fetchLabel = isLoading || !forTable ? '' : 'Fetch Excel';

  return (
    <Button disabled={buttonDisabled} onClick={handleExcelDownload}>
      {forTable && data && data.length ? label : fetchLabel}
      {!forTable ? label : null}
      {isLoading ? (
        <Preloader display={POSITIONS.HORIZONRAL} size={SIZES.SMALL} />
      ) : null}
    </Button>
  );
};

export default ExcelDownload;
