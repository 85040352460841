import routes from '@routes';

export const PAGE_LABELS = {
  [routes.dashboard]: 'Dashboard',
  [routes.users]: 'Users',
  [routes.contentPublication.index]: 'Content publications',
  [routes.contentPublication.show()]: 'Content publication',
  [routes.emails]: 'Emails',
  [routes.archivedArticles]: 'Archived articles',
  [routes.articlesAndClicks]: 'Articles and clicks',
  [routes.sentiments]: 'Sentiments',
  [routes.articleSourcesMatrix]: 'Article sources matrix',
  [routes.companiesRelations]: 'Companies and peers',
  [routes.analystsStatistics]: 'Statistics for analysts',
  [routes.alertsStatistics]: 'Alerts statistics',
  [routes.articleAggregates]: 'Article aggregates',
  [routes.summaryReports.index]: 'Summary reports',
  [routes.summaryReports.show()]: 'Summary report',
  [routes.contentReports.index]: 'Content reports',
  [routes.contentReports.show()]: 'Content report',
  [routes.newspapers]: 'Newspapers',
  [routes.notificationReports]: 'Notifications',
  [routes.nlaAggregates.index]: 'NLA usage aggregates',
  [routes.nlaAggregates.show()]: 'NLA usage aggregate',
  [routes.clientCoverages.index]: 'Client coverages',
  [routes.clientCoverages.show()]: 'Client coverages',
  [routes.aasReportRequest]: 'Articles and alerts summary',
};

export const CHART_COLORS = [
  '#29235C',
  '#2f4f4f',
  '#556b2f',
  '#8b4513',
  '#191970',
  '#8b0000',
  '#808000',
  '#778899',
  '#008000',
  '#3cb371',
  '#bc8f8f',
  '#cd853f',
  '#9acd32',
  '#00008b',
  '#32cd32',
  '#daa520',
  '#7f007f',
  '#8fbc8f',
  '#ff4500',
  '#00ced1',
  '#ff8c00',
  '#deb887',
  '#8a2be2',
  '#dc143c',
  '#00bfff',
  '#0000ff',
  '#da70d6',
  '#ff00ff',
  '#1e90ff',
  '#db7093',
  '#fa8072',
  '#dda0dd',
  '#ff1493',
  '#7b68ee',
];
