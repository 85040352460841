import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

import { Navigate, useLocation } from 'react-router';
import { ToastContainer } from 'react-toastify';

import ReactGA from 'react-ga4';
import ProtectedRoute from '@components/ProtectedRoute';
import Layout from '@components/Layout';

import GlobalStyle from '@styles/globalStyles';
import ThemesProvider from '@styles/theme';
import routes from '@routes';
import { PAGE_LABELS } from '@root/constants/common';
import Dashboard from '@containers/Dashboard';
import Users from '@containers/Users';
import ContentPublications from '@containers/ContentPublications';
import ContentPublication from '@containers/ContentPublications/details';
import ArticlesAndClicks from '@containers/ArticlesAndClicks';
import Emails from '@containers/Emails';
import ArchivedArticles from '@containers/ArchivedArticles';
import Sentiments from '@containers/Sentiments';
import NotFound from '@containers/NotFound';
import ArticleSourcesMatrix from '@containers/ArticleSourcesMatrix';
import CompaniesRelations from '@containers/CompaniesRelations';
import AnalystsStatistics from '@containers/AnalystsStatistics';
import AlertsStatistics from '@containers/AlertsStatistics';
import ArticleAggregates from '@containers/ArticleAggregates';
import SummaryReports from '@containers/SummaryReports';
import SummaryReport from '@containers/SummaryReports/details';
import ContentReports from '@containers/ContentReports';
import ContentReport from '@containers/ContentReports/details';
import Newspapers from '@containers/Newspapers';
import NotificationReports from '@containers/NotificationReports';
import NlaUsageAggregates from '@containers/NlaUsageAggregates';
import NlaAggregate from '@containers/NlaUsageAggregates/details';
import ClientCoverages from '@containers/ClientCoverages';
import ClientCoverage from '@containers/ClientCoverages/details';
import AASReportRequest from '@containers/AASReportRequest';

moment.updateLocale('en', {
  relativeTime: {
    ss: '%ds',
    mm: '%dm',
    hh: '%dh',
    dd: '%dd',
  },
});

const App = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
      ReactGA.initialize(TRACKING_ID);
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location]);

  return (
    <ThemesProvider>
      <GlobalStyle />
      <>
        <Routes>
          <Route
            exact
            path="/"
            element={<Navigate to={routes.dashboard} replace />}
          />
          <Route
            exact
            path={routes.dashboard}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.dashboard]}>
                  <Dashboard />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.users}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.users]}>
                  <Users />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.emails}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.emails]}>
                  <Emails />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.sentiments}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.sentiments]}>
                  <Sentiments />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.archivedArticles}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.archivedArticles]}>
                  <ArchivedArticles />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.contentPublication.index}
            element={
              <ProtectedRoute>
                <Layout
                  mobileLabel={PAGE_LABELS[routes.contentPublication.index]}
                >
                  <ContentPublications />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.articlesAndClicks}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.articlesAndClicks]}>
                  <ArticlesAndClicks />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.contentPublication.show()}
            element={
              <ProtectedRoute>
                <Layout
                  mobileLabel={PAGE_LABELS[routes.contentPublication.show()]}
                >
                  <ContentPublication />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.articleSourcesMatrix}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.articleSourcesMatrix]}>
                  <ArticleSourcesMatrix />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.companiesRelations}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.companiesRelations]}>
                  <CompaniesRelations />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.analystsStatistics}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.analystsStatistics]}>
                  <AnalystsStatistics />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.alertsStatistics}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.alertsStatistics]}>
                  <AlertsStatistics />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.articleAggregates}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.articleAggregates]}>
                  <ArticleAggregates />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.summaryReports.index}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.summaryReports.index]}>
                  <SummaryReports />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.summaryReports.show()}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.summaryReports.show()]}>
                  <SummaryReport />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.contentReports.index}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.contentReports.index]}>
                  <ContentReports />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.contentReports.show()}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.contentReports.show()]}>
                  <ContentReport />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.newspapers}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.newspapers]}>
                  <Newspapers />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.notificationReports}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.notificationReports]}>
                  <NotificationReports />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.nlaAggregates.index}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.nlaAggregates.index]}>
                  <NlaUsageAggregates />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.nlaAggregates.show()}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.nlaAggregates.show()]}>
                  <NlaAggregate />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.clientCoverages.index}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.clientCoverages.index]}>
                  <ClientCoverages />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.clientCoverages.show()}
            element={
              <ProtectedRoute>
                <Layout
                  mobileLabel={PAGE_LABELS[routes.clientCoverages.show()]}
                >
                  <ClientCoverage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.aasReportRequest}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.aasReportRequest]}>
                  <AASReportRequest />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <Layout mobileLabel="404 Not found">
                  <NotFound />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<div>404 Not found</div>} />
        </Routes>
        <ToastContainer
          autoClose={8000}
          position="bottom-right"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={false}
        />
      </>
    </ThemesProvider>
  );
};

export default App;
